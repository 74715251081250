// JsFromRoutes CacheKey d3a42da06b8e1c1f4175d92f67bf22d9
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@consumer/services/api'

export default {
  profile: /* #__PURE__ */ definePathHelper('get', '/my-account/profile'),
  purchases: /* #__PURE__ */ definePathHelper('get', '/my-account/purchases'),
  billing: /* #__PURE__ */ definePathHelper('get', '/my-account/billing'),
  settings: /* #__PURE__ */ definePathHelper('get', '/my-account/settings'),
  resetPassword: /* #__PURE__ */ definePathHelper('post', '/consumer/my_account/reset_password'),
  updateInfo: /* #__PURE__ */ definePathHelper('post', '/consumer/my_account/update_info'),
  updateSettings: /* #__PURE__ */ definePathHelper('post', '/consumer/my_account/update_settings'),
  deleteAccount: /* #__PURE__ */ definePathHelper('post', '/consumer/my_account/delete_account'),
}
